.header {
  background-color: #1D1E23; /* Replace with actual color or gradient */
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 30px; /* Center items horizontally */
  /* Additional styles */
}

.header-logo {
  height: 60px; /* Adjust size as needed */
  /* Additional styles */
}
.header-signin {
  width: 78px;
  height: 30px;
  top: 18px;
  left: 1163px;
  gap: 0px;
  border-radius: 3px 0px 0px 0px;
  opacity: 0px;
  background: #6904E9;
  border-radius: 3px;
  text-align: center;
  color: white;
}
.header-signin-text {
  font-family: Urbanist;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}
