.prompt-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto; /* Ensure consistent centering */
  width: 85%; /* Set a specific width for the container */
  height: auto;  
}
.bg-with-icon {
  /* background-image: url('../../assets//images/background-icons.svg'); */
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  min-height: 85vh;
}
.prompt-cont {
  width: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}

.prompt-input {
  width: 100%; /* Input takes full width of the container */
  padding: 15px;
  font-size: 1.2rem;
  background-color: #58007C;
  color: #fff;
  border: 1px solid #444;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
  outline: none;
  
}

.prompt-input::placeholder {
  color: #aaa;
  opacity: 1;
}

.generate-button {
  padding: 10px 20px;
  border: solid;
  border-color: #763bff;
  border-radius: 10%;
  background: transparent;
  color: #763bff;
  cursor: pointer;
  transition: color 0.3s;
}

.generate-button:hover {
  color: #3900bd;
}

.generate-button:focus {
  outline: none;
}